import actionTypes from './action.types';
import { apiUrlPublic, axiosCallApi } from '../../../config/config';
import { getToken } from '../../utils/utils';

export const addToCart = (product, quantity, extraQuantity) => ({
  type: actionTypes.CART_ADD_PRODUCT,
  product,
  quantity,
  extraQuantity
});

export const removeFromCart = (index) => ({
  type: actionTypes.CART_REMOVE_PRODUCT,
  index
});

export const onQuantityChange = (value, product) => ({
  type: actionTypes.CART_CHANGE_PRODUCT_QUANTITY,
  value,
  product
});

export const addMutlipleProductsToCart = (products) => ({
  type: actionTypes.CART_ADD_MULTIPLE_PRODUCTS,
  products
});

export const resetCart = () => ({
  type: actionTypes.CART_RESET
});

export const addToTempCart = (product, quantity, extraQuantity) => {
  return async (dispatch) => {
    function onSuccess(response) {
      if (getToken()) {
        const { product: responseProduct, quantity: responseQuantity } = response.data;
        dispatch(addToCart(responseProduct, responseQuantity));
      } else {
        const { product: notLoggedInProduct, quantity: notLoggedInQuantity } = response;
        dispatch(addToCart(notLoggedInProduct, notLoggedInQuantity));
      }
      return response;
    }
    function onError(error) {
      console.log(error);
      return error;
    }
    try {
      const data = {
        product,
        quantity,
        extraQuantity
      };
      /*
       we need to check if user is logged in or not,
       if not, do not send request for Temp Cart on server and just return product and quantity
      */
      if (getToken()) {
        const addProductToCart = await axiosCallApi.post(
          `${apiUrlPublic}/customer/cart`,
          data
        );
        return { data: onSuccess(addProductToCart) };
      }
      return { data: onSuccess({ product, quantity }) };
    } catch (error) {
      return { errorLogin: onError(error) };
    }
  };
};
