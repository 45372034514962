import React from 'react';
import slugify from 'slugify';
import { apiImageStatic } from '../../config/config';

const clip = require('text-clipper');

console.log('t')
// eslint-disable-next-line consistent-return
export const getToken = () => {
  if (typeof window !== 'undefined') {
    return localStorage.getItem('token');
  }
};

export const validEmail = (email) => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(String(email).toLowerCase());
};

export const truncateHtml = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  return clip(str, length, { html: true, indicator: ending });
};

export const truncateText = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending == null) {
    ending = '...';
  }
  if (str?.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const isTotallyEmpty = (obj) => {
  if (obj) {
    return Object.getOwnPropertyNames(obj).length === 0;
  }
  return true;
};

export const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false;
  delete obj.defaultAddress;
  delete obj.business;
  // eslint-disable-next-line no-prototype-builtins
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true;
  return checkNested(obj[level], ...rest);
};

// var emptyKeys = [];
// function isEmpty(obj, keysArr) {
//   for (var key in obj) {
//     if (typeof obj[key] === 'object' && obj.hasOwnProperty(key)) {
//       isEmpty(obj[key], keysArr);
//     } else {
//       if (obj[key] == '' || obj[key] == null) {
//         keysArr.push(key);
//       }
//     }
//   }
// }

// const isObjEmpty = (obj) => obj === null || Object.keys(obj).length === 0;
// export const isTotallyEmpty = (item) =>
//   item === undefined ||
//   item === '' ||
//   (Array.isArray(item) && array.every(isTotallyEmpty)) ||
//   (typeof item === 'object' && isObjEmpty(item));

export const isEmpty = (f) => Object.values(f).every((x) => x === '' || x.length === 0);

const hasFilters = (filters) => {
  if (
    filters.search ||
    filters.brands.length > 0 ||
    filters.tags.length > 0 ||
    filters.attributes.length > 0 ||
    filters.options.length > 0 ||
    filters.pricesRange.min >= 0 ||
    filters.pricesRange.max > 0
  )
    return true;
};

export const setFilters = (filters) => {
  if (hasFilters(filters)) {
    return `&filters=${JSON.stringify(filters)}`;
  }
  return '';
};

// Extra Small Image
export const getImageXS = (product) => {
  if (product.images.length > 0 && product.images[0].image.name) {
    return `${apiImageStatic}/product/${product._id}/${product.images[0].thumbs[0].name}`;
  } else {
    return 'https://via.placeholder.com/140x140.png?text=No Image';
  }
};

export const getHomeImg = (slider) => {
  // console.log(`test:s ${slider}`);
  return `${apiImageStatic}/slider/${slider._id}/${slider.image}`;
};

// Small Image
export const getImageSM = (product) => {
  if (product.images.length > 0 && product.images[0].image.name) {
    return `${apiImageStatic}/product/${product._id}/${product.images[0].thumbs[1].name}`;
  } else {
    return 'https://via.placeholder.com/140x140.png?text=No Image';
  }
};

// Medium Image
export const getImageMD = (product) => {
  if (product.images.length > 0 && product.images[0].image.name) {
    return `${apiImageStatic}/product/${product._id}/${product.images[0].thumbs[2].name}`;
  } else {
    return 'https://via.placeholder.com/140x140.png?text=No Image';
  }
};

// Large Image
export const getImageLG = (product) => {
  if (product.images.length > 0 && product.images[0].image.name) {
    return `${apiImageStatic}/product/${product._id}/${product.images[0].thumbs[1]?.name}`;
  } else {
    return 'https://via.placeholder.com/140x140.png?text=No Image';
  }
};

// Large Image
export const getImageLGVariant = (variant) => {
  if (variant.images.length > 0 && variant.images[0].image.name) {
    return `${apiImageStatic}/product/${variant.parent ? variant.parent : variant._id}/${
      variant.images[0].thumbs[3].name
    }`;
  } else {
    return 'https://via.placeholder.com/140x140.png?text=No Image';
  }
};

export const getMainImage = (product) => {
  if (product.images.length > 0 && product.images[0].image.name) {
    return {
      url: `${apiImageStatic}/product/${product._id}/${product.images[0].image.name}`,
      dimensions: {
        width: product.images[0].image.width,
        height: product.images[0].image.height
      },
      alt: product.images[0].image.alt
    };
  } else {
    return {
      url: 'https://via.placeholder.com/140x140.png?text=No Image',
      dimensions: {
        width: 140,
        height: 100
      },
      alt: product.slug
    };
  }
};

export const getGalleryPhoto = (product, layout, parentProduct) => {
  if (product.images.length > 0) {
    return `${apiImageStatic}/product/${product._id}/${product.images[0].image.name}`;
  } else if (parentProduct ? parentProduct.images.length > 0 : null) {
    return `${apiImageStatic}/product/${parentProduct._id}/${parentProduct.images[0].image.name}`;
  } else {
    return layout !== 'grid'
      ? 'https://via.placeholder.com/400x400.png?text=No Image'
      : 'https://via.placeholder.com/1000x250.png?text=No Image';
  }
};

export const settings = {
  decimal_separator: ',',
  decimal_number: 2,
  thousand_separator: '.',
  currency_format: '{amount} €'
};

// eslint-disable-next-line consistent-return
export const checkPrice = (product, quantity) => {
  if (product) {
    if (product.onSale.value) {
      return product.sale.salePrice * quantity;
    }
    return product.price * quantity;
  }
};

// eslint-disable-next-line consistent-return
export const checkOnlyPrice = (product) => {
  if (product) {
    if (product.onSale.value) {
      return product.sale.salePrice;
    }
    return product.price;
  }
};

export const inRange = (low, num, high, inclusive) => {
  inclusive = typeof inclusive === 'undefined' ? false : inclusive;
  if (inclusive && num >= low && num <= high) return true;
  return num > low && num < high;
};

export const formatNumber = (number) => {
  const x = 3;
  const floatNumber = parseFloat(number || 0) || 0;
  const re = `\\d(?=(\\d{${x}})+${settings.decimal_number > 0 ? '\\D' : '$'})`;
  const num = floatNumber.toFixed(Math.max(0, Math.floor(settings.decimal_number)));
  return (settings.decimal_separator
    ? num.replace('.', settings.decimal_separator)
    : num
  ).replace(new RegExp(re, 'g'), `$&${settings.thousand_separator}`);
};

const amountPattern = '{amount}';

export const formatCurrency = (number = 0) => {
  return settings.currency_format.replace(amountPattern, formatNumber(number, settings));
};

export const transformProduct = (product) => {
  return {
    _id: product._id,
    categories: product.categories,
    images: product.images,
    inventoryTracking: product?.inventoryTracking,
    layout: product.layout,
    onSale: product.onSale,
    options: product.options,
    parent: product.parent,
    parentId: product.product_id,
    price: product.price,
    productType: product.productType,
    quantity: product.quantity,
    sale: product.sale,
    shipping: product.shipping,
    sku: product.sku,
    slug: product.slug,
    status: product.status,
    stock: product.stock,
    title: product.title,
    totalPrice: product.totalPrice
  };
};

export const transformFavorite = (product) => {
  return {
    _id: product._id,
    images: product.images,
    onSale: product.onSale,
    price: product.price,
    sale: product.sale,
    shipping: product.shipping,
    sku: product.sku,
    slug: product.slug,
    status: product.status,
    stock: product.stock,
    title: product.title
  };
};

export const slugifyName = (name) => {
  if (name) {
    // replace unsafe characters
    return slugify(name, {
      lower: true,
      remove: /[*+~()/'"!:@,]/g,
      replacement: '-'
    });
  }
  return name;
};

// eslint-disable-next-line no-shadow
export const showProductPrice = (product, variant, settings) => {
  let type = product;
  if (variant) {
    type = variant;
  }

  return type?.onSale?.value ? (
    <>
      <span className="text-16-mute">Από: </span>
      <span className="text-16-mute pr-1 pl-1 line-through">
        {formatCurrency(type.price, settings)}
      </span>
      <span className="text-16-bold">
        {formatCurrency(type.sale.salePrice, settings)}
      </span>
    </>
  ) : (
    <span className="text-16-mute pr-1 pl-1">{formatCurrency(type.price, settings)}</span>
  );
};

export const emptyAddresses = [
  {
    activityType: '',
    address_1: '',
    address_2: '',
    business: false,
    city: '',
    companyName: '',
    country: { label: '', value: '', key: '' },
    county: '',
    defaultAddress: false,
    doy: '',
    fathersName: '',
    firstName: '',
    lastName: '',
    mobile: '',
    mobilePrefix: '',
    notes: '',
    phone: '',
    phonePrefix: '',
    postalCode: '',
    taxNo: '',
    territory: {
      label: '',
      value: ''
    },
    title: ''
  }
];

export const emptyCompanyInfo = [
  {
    activityType: '',
    address_1: '',
    address_2: '',
    city: '',
    companyName: '',
    country: { label: '', value: '', key: '' },
    doy: '',
    postalCode: '',
    taxNo: '',
    title: ''
  }
];

export const countriesGreek = [
  {
    label: 'ΑΦΓΑΝΙΣΤΑΝ',
    value: 'ΑΦΓΑΝΙΣΤΑΝ'
  },
  {
    label: 'ΑΛΒΑΝΙΑ',
    value: 'ΑΛΒΑΝΙΑ'
  },
  {
    label: 'ΑΛΓΕΡΙΑ',
    value: 'ΑΛΓΕΡΙΑ'
  },
  {
    label: 'ΑΝΔΟΡΑ',
    value: 'ΑΝΔΟΡΑ'
  },
  {
    label: 'ΑΓΚΟΛΑ',
    value: 'ΑΓΚΟΛΑ'
  },
  {
    label: 'ΑΝΓΚΟΥΙΛΑ',
    value: 'ΑΝΓΚΟΥΙΛΑ'
  },
  {
    label: 'ΑΝΤΙΓΚΟΥΑ & ΜΠΑΡΜΠΟΥΝΤΑ',
    value: 'ΑΝΤΙΓΚΟΥΑ & ΜΠΑΡΜΠΟΥΝΤΑ'
  },
  {
    label: 'ΑΡΓΕΝΤΙΝΗ',
    value: 'ΑΡΓΕΝΤΙΝΗ'
  },
  {
    label: 'ΑΡΜΕΝΙΑ',
    value: 'ΑΡΜΕΝΙΑ'
  },
  {
    label: 'ΑΡΟΥΜΠΑ',
    value: 'ΑΡΟΥΜΠΑ'
  },
  {
    label: 'ΑΥΣΤΡΑΛΙΑ',
    value: 'ΑΥΣΤΡΑΛΙΑ'
  },
  {
    label: 'ΑΥΣΤΡΙΑ',
    value: 'ΑΥΣΤΡΙΑ'
  },
  {
    label: 'ΑΖΕΡΜΠΑΪΤΖΑΝ',
    value: 'ΑΖΕΡΜΠΑΪΤΖΑΝ'
  },
  {
    label: 'ΜΠΑΧΑΜΕΣ',
    value: 'ΜΠΑΧΑΜΕΣ'
  },
  {
    label: 'ΜΠΑΧΡΕΙΝ',
    value: 'ΜΠΑΧΡΕΙΝ'
  },
  {
    label: 'ΜΠΑΓΚΛΑΝΤΕΣ',
    value: 'ΜΠΑΓΚΛΑΝΤΕΣ'
  },
  {
    label: 'ΜΠΑΡΜΠΑΝΤΟΣ',
    value: 'ΜΠΑΡΜΠΑΝΤΟΣ'
  },
  {
    label: 'ΛΕΥΚΟΡΩΣΙΑ',
    value: 'ΛΕΥΚΟΡΩΣΙΑ'
  },
  {
    label: 'ΒΕΛΓΙΟ',
    value: 'ΒΕΛΓΙΟ'
  },
  {
    label: 'ΜΠΕΛΙΖΕ',
    value: 'ΜΠΕΛΙΖΕ'
  },
  {
    label: 'ΜΠΕΝΙΝ',
    value: 'ΜΠΕΝΙΝ'
  },
  {
    label: 'ΒΕΡΜΟΥΔΑ',
    value: 'ΒΕΡΜΟΥΔΑ'
  },
  {
    label: 'ΜΠΟΥΤΑΝ',
    value: 'ΜΠΟΥΤΑΝ'
  },
  {
    label: 'ΒΟΛΙΒΙΑ',
    value: 'ΒΟΛΙΒΙΑ'
  },
  {
    label: 'ΒΟΣΝΙΑ & ΕΡΖΕΓΟΒΙΝΗ',
    value: 'ΒΟΣΝΙΑ & ΕΡΖΕΓΟΒΙΝΗ'
  },
  {
    label: 'ΜΠΟΤΣΟΥΑΝΑ',
    value: 'ΜΠΟΤΣΟΥΑΝΑ'
  },
  {
    label: 'ΒΡΑΖΙΛΙΑ',
    value: 'ΒΡΑΖΙΛΙΑ'
  },
  {
    label: 'ΒΡΕΤΑΝΙΚΑ ΠΑΡΘΕΝΑ ΝΗΣΙΑ',
    value: 'ΒΡΕΤΑΝΙΚΑ ΠΑΡΘΕΝΑ ΝΗΣΙΑ'
  },
  {
    label: 'ΜΠΡΟΥΝΕΙ',
    value: 'ΜΠΡΟΥΝΕΙ'
  },
  {
    label: 'ΒΟΥΛΓΑΡΙΑ',
    value: 'ΒΟΥΛΓΑΡΙΑ'
  },
  {
    label: 'ΜΠΟΥΡΚΙΝΑ ΦΑΣΟ',
    value: 'ΜΠΟΥΡΚΙΝΑ ΦΑΣΟ'
  },
  {
    label: 'ΜΠΟΥΡΟΥΝΤΙ',
    value: 'ΜΠΟΥΡΟΥΝΤΙ'
  },
  {
    label: 'ΚΑΜΠΟΤΖΗ',
    value: 'ΚΑΜΠΟΤΖΗ'
  },
  {
    label: 'ΚΑΜΕΡΟΥΝ',
    value: 'ΚΑΜΕΡΟΥΝ'
  },
  {
    label: 'ΠΡΑΣΙΝΟ ΑΚΡΩΤΗΡΙΟ',
    value: 'ΠΡΑΣΙΝΟ ΑΚΡΩΤΗΡΙΟ'
  },
  {
    label: 'ΝΗΣΟΙ ΚΕΙΜΑΝ',
    value: 'ΝΗΣΟΙ ΚΕΙΜΑΝ'
  },
  {
    label: 'ΤΣΑΝΤ',
    value: 'ΤΣΑΝΤ'
  },
  {
    label: 'ΧΙΛΗ',
    value: 'ΧΙΛΗ'
  },
  {
    label: 'ΚΙΝΑ',
    value: 'ΚΙΝΑ'
  },
  {
    label: 'ΚΟΛΟΜΒΙΑ',
    value: 'ΚΟΛΟΜΒΙΑ'
  },
  {
    label: 'ΚΟΓΚΟ',
    value: 'ΚΟΓΚΟ'
  },
  {
    label: 'ΝΗΣΟΙ ΚΟΥΚ',
    value: 'ΝΗΣΟΙ ΚΟΥΚ'
  },
  {
    label: 'ΚΟΣΤΑ ΡΙΚΑ',
    value: 'ΚΟΣΤΑ ΡΙΚΑ'
  },
  {
    label: 'ΑΚΤΗ ΕΛΕΦΑΝΤΟΣΤΟΥ',
    value: 'ΑΚΤΗ ΕΛΕΦΑΝΤΟΣΤΟΥ'
  },
  {
    label: 'ΚΡΟΑΤΙΑ',
    value: 'ΚΡΟΑΤΙΑ'
  },
  {
    label: 'ΚΡΟΥΑΖΙΕΡΟΠΛΟΙΟ',
    value: 'ΚΡΟΥΑΖΙΕΡΟΠΛΟΙΟ'
  },
  {
    label: 'ΚΟΥΒΑ',
    value: 'ΚΟΥΒΑ'
  },
  {
    label: 'ΚΥΠΡΟΣ',
    value: 'ΚΥΠΡΟΣ'
  },
  {
    label: 'ΤΣΕΧΙΚΗ ΔΗΜΟΚΡΑΤΙΑ',
    value: 'ΤΣΕΧΙΚΗ ΔΗΜΟΚΡΑΤΙΑ'
  },
  {
    label: 'ΔΑΝΙΑ',
    value: 'ΔΑΝΙΑ'
  },
  {
    label: 'ΤΖΙΜΠΟΥΤΙ',
    value: 'ΤΖΙΜΠΟΥΤΙ'
  },
  {
    label: 'ΔΟΜΙΝΙΚΑ',
    value: 'ΔΟΜΙΝΙΚΑ'
  },
  {
    label: 'ΔΟΜΙΝΙΚΑΝΗ ΔΗΜΟΚΡΑΤΙΑ',
    value: 'ΔΟΜΙΝΙΚΑΝΗ ΔΗΜΟΚΡΑΤΙΑ'
  },
  {
    label: 'ΕΚΟΥΑΔΟΡ',
    value: 'ΕΚΟΥΑΔΟΡ'
  },
  {
    label: 'ΑΙΓΥΠΤΟΣ',
    value: 'ΑΙΓΥΠΤΟΣ'
  },
  {
    label: 'ΕΛ ΣΑΛΒΑΔΟΡ',
    value: 'ΕΛ ΣΑΛΒΑΔΟΡ'
  },
  {
    label: 'ΙΣΗΜΕΡΙΝΗ ΓΟΥΙΝΕΑ',
    value: 'ΙΣΗΜΕΡΙΝΗ ΓΟΥΙΝΕΑ'
  },
  {
    label: 'ΕΣΘΟΝΙΑ',
    value: 'ΕΣΘΟΝΙΑ'
  },
  {
    label: 'ΑΙΘΙΟΠΙΑ',
    value: 'ΑΙΘΙΟΠΙΑ'
  },
  {
    label: 'ΝΗΣΟΙ ΦΩΚΛΑΝΤ',
    value: 'ΝΗΣΟΙ ΦΩΚΛΑΝΤ'
  },
  {
    label: 'ΝΗΣΟΙ ΦΕΡΟΕΣ',
    value: 'ΝΗΣΟΙ ΦΕΡΟΕΣ'
  },
  {
    label: 'ΦΙΤΖΙ',
    value: 'ΦΙΤΖΙ'
  },
  {
    label: 'ΦΙΝΛΑΝΔΙΑ',
    value: 'ΦΙΝΛΑΝΔΙΑ'
  },
  {
    label: 'ΓΑΛΛΙΑ',
    value: 'ΓΑΛΛΙΑ'
  },
  {
    label: 'ΓΑΛΛΙΚΗ ΠΟΛΥΝΗΣΙΑ',
    value: 'ΓΑΛΛΙΚΗ ΠΟΛΥΝΗΣΙΑ'
  },
  {
    label: 'ΓΑΛΛΙΚΕΣ ΔΥΤΙΚΕΣ ΙΝΔΙΕΣ',
    value: 'ΓΑΛΛΙΚΕΣ ΔΥΤΙΚΕΣ ΙΝΔΙΕΣ'
  },
  {
    label: 'ΓΚΑΜΠΟΝ',
    value: 'ΓΚΑΜΠΟΝ'
  },
  {
    label: 'ΓΚΑΜΠΙΑ',
    value: 'ΓΚΑΜΠΙΑ'
  },
  {
    label: 'ΓΕΩΡΓΙΑ',
    value: 'ΓΕΩΡΓΙΑ'
  },
  {
    label: 'ΓΕΡΜΑΝΙΑ',
    value: 'ΓΕΡΜΑΝΙΑ'
  },
  {
    label: 'ΓΚΑΝΑ',
    value: 'ΓΚΑΝΑ'
  },
  {
    label: 'ΓΙΒΡΑΛΤΑΡ',
    value: 'ΓΙΒΡΑΛΤΑΡ'
  },
  {
    label: 'ΕΛΛΑΔΑ',
    value: 'ΕΛΛΑΔΑ'
  },
  {
    label: 'ΓΡΟΙΛΑΝΔΙΑ',
    value: 'ΓΡΟΙΛΑΝΔΙΑ'
  },
  {
    label: 'ΓΡΕΝΑΔΑ',
    value: 'ΓΡΕΝΑΔΑ'
  },
  {
    label: 'ΓΚΟΥΑΜ',
    value: 'ΓΚΟΥΑΜ'
  },
  {
    label: 'ΓΟΥΑΤΕΜΑΛΑ',
    value: 'ΓΟΥΑΤΕΜΑΛΑ'
  },
  {
    label: 'Guernsey',
    value: 'Guernsey'
  },
  {
    label: 'ΓΟΥΙΝΕΑ',
    value: 'ΓΟΥΙΝΕΑ'
  },
  {
    label: 'ΓΟΥΙΝΕΑ ΜΠΙΣΣΑΟΥ',
    value: 'ΓΟΥΙΝΕΑ ΜΠΙΣΣΑΟΥ'
  },
  {
    label: 'ΓΟΥΙΑΝΑ',
    value: 'ΓΟΥΙΑΝΑ'
  },
  {
    label: 'ΑΪΤΗ',
    value: 'ΑΪΤΗ'
  },
  {
    label: 'ΟΝΔΟΥΡΑ',
    value: 'ΟΝΔΟΥΡΑ'
  },
  {
    label: 'ΧΟΝΓΚ ΚΟΝΓΚ',
    value: 'ΧΟΝΓΚ ΚΟΝΓΚ'
  },
  {
    label: 'ΟΥΓΓΑΡΙΑ',
    value: 'ΟΥΓΓΑΡΙΑ'
  },
  {
    label: 'ΙΣΛΑΝΔΙΑ',
    value: 'ΙΣΛΑΝΔΙΑ'
  },
  {
    label: 'ΙΝΔΙΑ',
    value: 'ΙΝΔΙΑ'
  },
  {
    label: 'ΙΝΔΟΝΗΣΙΑ',
    value: 'ΙΝΔΟΝΗΣΙΑ'
  },
  {
    label: 'ΙΡΑΝ',
    value: 'ΙΡΑΝ'
  },
  {
    label: 'ΙΡΑΚ',
    value: 'ΙΡΑΚ'
  },
  {
    label: 'ΙΡΛΑΝΔΙΑ',
    value: 'ΙΡΛΑΝΔΙΑ'
  },
  {
    label: 'ΝΗΣΟΣ ΤΟΥ ΜΑΝ',
    value: 'ΝΗΣΟΣ ΤΟΥ ΜΑΝ'
  },
  {
    label: 'ΙΣΡΑΗΛ',
    value: 'ΙΣΡΑΗΛ'
  },
  {
    label: 'ΙΤΑΛΙΑ',
    value: 'ΙΤΑΛΙΑ'
  },
  {
    label: 'ΤΖΑΜΑΙΚΑ',
    value: 'ΤΖΑΜΑΙΚΑ'
  },
  {
    label: 'ΙΑΠΩΝΙΑ',
    value: 'ΙΑΠΩΝΙΑ'
  },
  {
    label: 'ΤΖΕΡΣΕΪ',
    value: 'ΤΖΕΡΣΕΪ'
  },
  {
    label: 'ΙΟΡΔΑΝΙΑ',
    value: 'ΙΟΡΔΑΝΙΑ'
  },
  {
    label: 'ΚΑΖΑΚΣΤΑΝ',
    value: 'ΚΑΖΑΚΣΤΑΝ'
  },
  {
    label: 'ΚΕΝΥΑ',
    value: 'ΚΕΝΥΑ'
  },
  {
    label: 'ΚΟΥΒΕΙΤ',
    value: 'ΚΟΥΒΕΙΤ'
  },
  {
    label: 'ΚΙΡΓΙΖΙΑ',
    value: 'ΚΙΡΓΙΖΙΑ'
  },
  {
    label: 'ΛΑΟΣ',
    value: 'ΛΑΟΣ'
  },
  {
    label: 'ΛΕΣΒΟ',
    value: 'ΛΕΣΒΟ'
  },
  {
    label: 'ΛΙΒΕΡΙΑ',
    value: 'ΛΙΒΕΡΙΑ'
  },
  {
    label: 'ΛΙΒΥΗ',
    value: 'ΛΙΒΥΗ'
  },
  {
    label: 'ΛΙΧΤΕΝΣΤΑΙΝ',
    value: 'ΛΙΧΤΕΝΣΤΑΙΝ'
  },
  {
    label: 'ΛΙΘΟΥΑΝΙΑ',
    value: 'ΛΙΘΟΥΑΝΙΑ'
  },
  {
    label: 'ΛΟΥΞΕΜΒΟΥΡΓΟ',
    value: 'ΛΟΥΞΕΜΒΟΥΡΓΟ'
  },
  {
    label: 'ΜΑΚΑΟ',
    value: 'ΜΑΚΑΟ'
  },
  {
    label: 'ΜΑΚΕΔΟΝΙΑ',
    value: 'ΜΑΚΕΔΟΝΙΑ'
  },
  {
    label: 'ΜΑΔΑΓΑΣΚΑΡΗ',
    value: 'ΜΑΔΑΓΑΣΚΑΡΗ'
  },
  {
    label: 'ΜΑΛΑΟΥΙ',
    value: 'ΜΑΛΑΟΥΙ'
  },
  {
    label: 'ΜΑΛΑΙΣΙΑ',
    value: 'ΜΑΛΑΙΣΙΑ'
  },
  {
    label: 'ΜΑΛΔΙΒΕΣ',
    value: 'ΜΑΛΔΙΒΕΣ'
  },
  {
    label: 'ΜΑΛΙ',
    value: 'ΜΑΛΙ'
  },
  {
    label: 'ΜΑΛΤΑ',
    value: 'ΜΑΛΤΑ'
  },
  {
    label: 'ΜΑΥΡΙΤΑΝΙΑ',
    value: 'ΜΑΥΡΙΤΑΝΙΑ'
  },
  {
    label: 'ΜΑΥΡΙΚΙΟΣ',
    value: 'ΜΑΥΡΙΚΙΟΣ'
  },
  {
    label: 'ΜΕΞΙΚΟ',
    value: 'ΜΕΞΙΚΟ'
  },
  {
    label: 'ΜΟΛΔΑΒΙΑ',
    value: 'ΜΟΛΔΑΒΙΑ'
  },
  {
    label: 'ΜΟΝΑΚΟ',
    value: 'ΜΟΝΑΚΟ'
  },
  {
    label: 'ΜΟΓΓΟΛΙΑ',
    value: 'ΜΟΓΓΟΛΙΑ'
  },
  {
    label: 'ΜΑΥΡΟΒΟΥΝΙΟ',
    value: 'ΜΑΥΡΟΒΟΥΝΙΟ'
  },
  {
    label: 'ΜΟΝΣΕΡΑΤ',
    value: 'ΜΟΝΣΕΡΑΤ'
  },
  {
    label: 'ΜΑΡΟΚΟ',
    value: 'ΜΑΡΟΚΟ'
  },
  {
    label: 'ΜΟΖΑΜΒΙΚΗ',
    value: 'ΜΟΖΑΜΒΙΚΗ'
  },
  {
    label: 'ΝΑΜΙΜΠΙΑ',
    value: 'ΝΑΜΙΜΠΙΑ'
  },
  {
    label: 'ΝΕΠΑΛ',
    value: 'ΝΕΠΑΛ'
  },
  {
    label: 'ΟΛΛΑΝΔΙΑ',
    value: 'ΟΛΛΑΝΔΙΑ'
  },
  {
    label: 'ΟΛΛΑΝΔΙΚΕΣ ΑΝΤΙΛΛΕΣ',
    value: 'ΟΛΛΑΝΔΙΚΕΣ ΑΝΤΙΛΛΕΣ'
  },
  {
    label: 'ΝΕΑ ΚΑΛΗΔΟΝΙΑ',
    value: 'ΝΕΑ ΚΑΛΗΔΟΝΙΑ'
  },
  {
    label: 'ΝΕΑ ΖΗΛΑΝΔΙΑ',
    value: 'ΝΕΑ ΖΗΛΑΝΔΙΑ'
  },
  {
    label: 'ΝΙΚΑΡΑΓΟΥΑ',
    value: 'ΝΙΚΑΡΑΓΟΥΑ'
  },
  {
    label: 'ΝΙΓΗΡΑΣ',
    value: 'ΝΙΓΗΡΑΣ'
  },
  {
    label: 'ΝΙΓΗΡΙΑ',
    value: 'ΝΙΓΗΡΙΑ'
  },
  {
    label: 'ΝΟΡΒΗΓΙΑ',
    value: 'ΝΟΡΒΗΓΙΑ'
  },
  {
    label: 'ΟΜΑΝ',
    value: 'ΟΜΑΝ'
  },
  {
    label: 'ΠΑΚΙΣΤΑΝ',
    value: 'ΠΑΚΙΣΤΑΝ'
  },
  {
    label: 'ΠΑΛΑΙΣΤΙΝΗ',
    value: 'ΠΑΛΑΙΣΤΙΝΗ'
  },
  {
    label: 'ΠΑΝΑΜΑΣ',
    value: 'ΠΑΝΑΜΑΣ'
  },
  {
    label: 'ΠΑΠΟΥΑ ΝΕΑ ΓΟΥΙΝΕΑ',
    value: 'ΠΑΠΟΥΑ ΝΕΑ ΓΟΥΙΝΕΑ'
  },
  {
    label: 'ΠΑΡΑΓΟΥΑΗ',
    value: 'ΠΑΡΑΓΟΥΑΗ'
  },
  {
    label: 'ΠΕΡΟΥ',
    value: 'ΠΕΡΟΥ'
  },
  {
    label: 'ΦΙΛΙΠΠΙΝΕΣ',
    value: 'ΦΙΛΙΠΠΙΝΕΣ'
  },
  {
    label: 'ΠΟΛΩΝΙΑ',
    value: 'ΠΟΛΩΝΙΑ'
  },
  {
    label: 'ΠΟΡΤΟΓΑΛΙΑ',
    value: 'ΠΟΡΤΟΓΑΛΙΑ'
  },
  {
    label: 'ΠΟΥΕΡΤΟ ΡΙΚΟ',
    value: 'ΠΟΥΕΡΤΟ ΡΙΚΟ'
  },
  {
    label: 'ΚΑΤΑΡ',
    value: 'ΚΑΤΑΡ'
  },
  {
    label: 'ΡΕΟΥΝΙΟΝ',
    value: 'ΡΕΟΥΝΙΟΝ'
  },
  {
    label: 'ΡΟΥΜΑΝΙΑ',
    value: 'ΡΟΥΜΑΝΙΑ'
  },
  {
    label: 'ΡΩΣΙΑ',
    value: 'ΡΩΣΙΑ'
  },
  {
    label: 'ΡΟΥΑΝΤΑ',
    value: 'ΡΟΥΑΝΤΑ'
  },
  {
    label: 'ΣΑΙΝΤ ΠΙΕΡ ΚΑΙ ΜΙΚΕΛΟΝ',
    value: 'ΣΑΙΝΤ ΠΙΕΡ ΚΑΙ ΜΙΚΕΛΟΝ'
  },
  {
    label: 'ΣΑΜΟΑ',
    value: 'ΣΑΜΟΑ'
  },
  {
    label: 'ΣΑΝ ΜΑΡΙΝΟ',
    value: 'ΣΑΝ ΜΑΡΙΝΟ'
  },
  {
    label: 'ΔΟΡΥΦΟΡΟΣ',
    value: 'ΔΟΡΥΦΟΡΟΣ'
  },
  {
    label: 'ΣΑΟΥΔΙΚΗ ΑΡΑΒΙΑ',
    value: 'ΣΑΟΥΔΙΚΗ ΑΡΑΒΙΑ'
  },
  {
    label: 'ΣΕΝΕΓΑΛΗ',
    value: 'ΣΕΝΕΓΑΛΗ'
  },
  {
    label: 'ΣΕΡΒΙΑ',
    value: 'ΣΕΡΒΙΑ'
  },
  {
    label: 'ΣΕΫΧΕΛΛΕΣ',
    value: 'ΣΕΫΧΕΛΛΕΣ'
  },
  {
    label: 'ΣΙΕΡΡΑ ΛΕΟΝΕ',
    value: 'ΣΙΕΡΡΑ ΛΕΟΝΕ'
  },
  {
    label: 'ΣΙΓΚΑΠΟΥΡΗ',
    value: 'ΣΙΓΚΑΠΟΥΡΗ'
  },
  {
    label: 'ΣΛΟΒΑΚΙΑ',
    value: 'ΣΛΟΒΑΚΙΑ'
  },
  {
    label: 'ΣΛΟΒΕΝΙΑ',
    value: 'ΣΛΟΒΕΝΙΑ'
  },
  {
    label: 'ΝΟΤΙΑ ΑΦΡΙΚΗ',
    value: 'ΝΟΤΙΑ ΑΦΡΙΚΗ'
  },
  {
    label: 'ΝΟΤΙΑ ΚΟΡΕΑ',
    value: 'ΝΟΤΙΑ ΚΟΡΕΑ'
  },
  {
    label: 'ΙΣΠΑΝΙΑ',
    value: 'ΙΣΠΑΝΙΑ'
  },
  {
    label: 'ΣΡΙ ΛΑΝΚΑ',
    value: 'ΣΡΙ ΛΑΝΚΑ'
  },
  {
    label: 'ΆΓΙΟΣ ΧΡΙΣΤΟΦΟΡΟΣ ΚΑΙ ΝΕΒΙΣ',
    value: 'ΆΓΙΟΣ ΧΡΙΣΤΟΦΟΡΟΣ ΚΑΙ ΝΕΒΙΣ'
  },
  {
    label: 'ΑΓΙΑ ΛΟΥΚΙΑ',
    value: 'ΑΓΙΑ ΛΟΥΚΙΑ'
  },
  {
    label: 'ΆΓΙΟΣ ΒΙΚΕΝΤΙΟΣ',
    value: 'ΆΓΙΟΣ ΒΙΚΕΝΤΙΟΣ'
  },
  {
    label: 'ΣΟΥΔΑΝ',
    value: 'ΣΟΥΔΑΝ'
  },
  {
    label: 'ΣΟΥΡΙΝΑΜ',
    value: 'ΣΟΥΡΙΝΑΜ'
  },
  {
    label: 'ΣΟΥΑΖΙΛΑΝΔΗ',
    value: 'ΣΟΥΑΖΙΛΑΝΔΗ'
  },
  {
    label: 'ΣΟΥΗΔΙΑ',
    value: 'ΣΟΥΗΔΙΑ'
  },
  {
    label: 'ΕΛΒΕΤΙΑ',
    value: 'ΕΛΒΕΤΙΑ'
  },
  {
    label: 'ΣΥΡΙΑ',
    value: 'ΣΥΡΙΑ'
  },
  {
    label: 'ΤΑΪΒΑΝ',
    value: 'ΤΑΪΒΑΝ'
  },
  {
    label: 'ΤΑΤΖΙΚΙΣΤΑΝ',
    value: 'ΤΑΤΖΙΚΙΣΤΑΝ'
  },
  {
    label: 'ΤΑΝΖΑΝΙΑ',
    value: 'ΤΑΝΖΑΝΙΑ'
  },
  {
    label: 'ΤΑΪΛΑΝΔΗ',
    value: 'ΤΑΪΛΑΝΔΗ'
  },
  {
    label: 'TIMOR-LESTE',
    value: 'TIMOR-LESTE'
  },
  {
    label: 'ΤΟΓΚΟ',
    value: 'ΤΟΓΚΟ'
  },
  {
    label: 'ΤΟΝΓΚΑ',
    value: 'ΤΟΝΓΚΑ'
  },
  {
    label: 'ΤΡΙΝΙΝΤΑΝΤ ΚΑΙ ΤΟΜΠΑΓΚΟ',
    value: 'ΤΡΙΝΙΝΤΑΝΤ ΚΑΙ ΤΟΜΠΑΓΚΟ'
  },
  {
    label: 'ΤΥΝΗΣΙΑ',
    value: 'ΤΥΝΗΣΙΑ'
  },
  {
    label: 'ΤΟΥΡΚΙΑ',
    value: 'ΤΟΥΡΚΙΑ'
  },
  {
    label: 'ΤΟΥΡΚΜΕΝΙΣΤΑΝ',
    value: 'ΤΟΥΡΚΜΕΝΙΣΤΑΝ'
  },
  {
    label: 'ΤΟΥΡΚΙΑ ΚΑΙ ΚΑΙΚΟΣ',
    value: 'ΤΟΥΡΚΙΑ ΚΑΙ ΚΑΙΚΟΣ'
  },
  {
    label: 'ΟΥΓΚΑΝΤΑ',
    value: 'ΟΥΓΚΑΝΤΑ'
  },
  {
    label: 'ΟΥΚΡΑΝΙΑ',
    value: 'ΟΥΚΡΑΝΙΑ'
  },
  {
    label: 'ΗΝΩΜΕΝΑ ΑΡΑΒΙΚΑ ΕΜΙΡΑΤΑ',
    value: 'ΗΝΩΜΕΝΑ ΑΡΑΒΙΚΑ ΕΜΙΡΑΤΑ'
  },
  {
    label: 'ΗΝΩΜΕΝΟ ΒΑΣΙΛΕΙΟ',
    value: 'ΗΝΩΜΕΝΟ ΒΑΣΙΛΕΙΟ'
  },
  {
    label: 'ΟΥΡΟΥΓΟΥΑΗ',
    value: 'ΟΥΡΟΥΓΟΥΑΗ'
  },
  {
    label: 'ΟΥΖΜΠΕΚΙΣΤΑΝ',
    value: 'ΟΥΖΜΠΕΚΙΣΤΑΝ'
  },
  {
    label: 'ΒΕΝΕΖΟΥΕΛΑ',
    value: 'ΒΕΝΕΖΟΥΕΛΑ'
  },
  {
    label: 'ΒΙΕΤΝΑΜ',
    value: 'ΒΙΕΤΝΑΜ'
  },
  {
    label: 'ΠΑΡΘΕΝΟΙ ΝΗΣΟΙ',
    value: 'ΠΑΡΘΕΝΟΙ ΝΗΣΟΙ'
  },
  {
    label: 'ΥΕΜΕΝΗ',
    value: 'ΥΕΜΕΝΗ'
  },
  {
    label: 'ΖΑΜΠΙΑ',
    value: 'ΖΑΜΠΙΑ'
  },
  {
    label: 'ΖΙΜΠΑΜΠΟΥΕ',
    value: 'ΖΙΜΠΑΜΠΟΥΕ'
  }
];

const NextArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <img
      alt="next-arrow"
      className={className}
      onClick={onClick}
      /* eslint-disable-next-line global-require */
      src="/static/images/home/right-arrow.png"
      style={{ ...style, display: 'block' }}
    />
  );
};

const PrevArrow = (props) => {
  const { className, onClick, style } = props;
  return (
    <img
      alt="next-arrow"
      className={className}
      onClick={onClick}
      /* eslint-disable-next-line global-require */
      src="/static/images/home/left-arrow.png"
      style={{ ...style, display: 'block' }}
    />
  );
};

export const SliderSettingsEarplugs = {
  slidesToShow: 2,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  responsive: [
    {
      breakpoint: 1329,
      settings: {
        arrows: false,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }
  ]
};

export const SliderSettingsCategorieBox = {
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: false,
  dots: false,
  autoplay: false,
  infinite: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 900,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

export const responsiveSliderSettingsGrid = {
  className: 'slick-slider-con',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 4,
  swipeToSlide: true,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  row: 1,
  adaptiveHeight: true,
  slidesToScroll: 1
};

export const responsiveSliderSettings = {
  className: 'slick-slider-con',
  infinite: true,
  centerPadding: '60px',
  slidesToShow: 4,
  dots: true,
  swipeToSlide: true,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1399,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 900,
      settings: {
        centerPadding: '60px',
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        dots: false,
        initialSlide: 1,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};
export const responsiveSliderSettingsSingle = {
  className: '',
  dots: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  adaptiveHeight: true,
  arrows: false,
  row: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />
};
export const responsiveSliderSettings2 = {
  className: 'slick-slider-con',
  infinite: true,
  slidesToShow: 2,
  row: 1,
  swipeToSlide: true,
  dots: true,
  arrows: false,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};
export const responsiveSliderSettingsProduct = {
  className: 'slick-slider-con',
  infinite: true,
  slidesToShow: 3,
  row: 1,
  autoplay: false,
  autoplaySpeed: 5000,
  swipeToSlide: true,
  dots: false,
  arrows: true,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 576,
      settings: {
        slidesToShow: 1
      }
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2
      }
    }
  ]
};
export const responsiveSliderSettings3 = {
  className: '',
  dots: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  adaptiveHeight: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        arrows: true,
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 3
      }
    },
    {
      breakpoint: 900,
      settings: {
        centerPadding: '60px',
        dots: false,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        arrows: false,
        dots: false,
        initialSlide: 1,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

export const responsiveSliderSettingsLong = {
  arrows: true,
  autoplay: true,
  autoplaySpeed: 8000,
  dots: false,
  infinite: true,
  slidesToScroll: 1,
  slidesToShow: 1,
  speed: 1500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 4
      }
    },
    {
      breakpoint: 768,
      settings: {
        centerPadding: '60px',
        dots: true,
        infinite: true,
        slidesToScroll: 1,
        slidesToShow: 2
      }
    },
    {
      breakpoint: 600,
      settings: {
        initialSlide: 1,
        slidesToScroll: 1,
        slidesToShow: 1
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToScroll: 1,
        slidesToShow: 1
      }
    }
  ]
};

/* export const sliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 1500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000
}; */
